jQuery(document).ready(function ($) {

    'use-strict';

    var $window = $(window);

    function LanguageSelect_toggle() {
        $(".main-nav").each(function () {
            var $mainNav = $(this);

            $(this).find(".language-select-trigger-show").on('click', function (event) {
                event.preventDefault();
                var $triggerShow = $(this);
                if (!($mainNav.hasClass('nav-language-select-opened'))) {
                    $mainNav.addClass('nav-language-select-opened');

                    if ($mainNav.hasClass('main-menu-opened')) {
                        $mainNav.removeClass('main-menu-opened');

                        setTimeout(function () {
                            $mainNav
                                .find('.nav-main-menu.small-screen .menu-dropdown > a')
                                .removeClass('menu-trigger')
                                .siblings('ul').hide();
                        }, 400);
                    }
                }
            });

            $(this).find('.language-select-trigger-hide').on('click', function (event) {
                event.preventDefault();
                /* Act on the event */
                $mainNav.removeClass('nav-language-select-opened');
            });
        });
    }

    LanguageSelect_toggle();
});